div.ReactModal__Content.ReactModal__Content--after-open {
  border: none !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 30px !important;
  inset: unset !important;
  padding: 30px !important;
  max-width: 400px;
  text-align: center;
}

div.ReactModalPortal {
  z-index: 100000000;
  position: relative;
}

div.ReactModal__Overlay.ReactModal__Overlay--after-open {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-modal {
  position: absolute;
  right: 40px;
  font-size: 24px;
  cursor: pointer;
}

div.help-info h2 {
  margin-top: 0em;
}

div.alphabet-soup {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  div.alphabet-soup {
    height: -webkit-fill-available;
  }
}

.keyboard-row {
  display: flex;
}

div.spacer {
  flex-grow: 1;
}

div.guess.invalid div.letter {
  background: #f44336;
}

/*.letter {*/
/*  border: 1px solid;*/
/*  border-radius: 0.1em;*/
/*  width: 3em;*/
/*  font-size: 1.5em;*/
/*  height: 3em;*/
/*  margin-right: 0.25em;*/
/*  margin-bottom: 0.25em;*/
/*  background: lightgray;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

.letter {
  width: 2em;
  font-size: inherit;
  height: 2em;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
  background: #607d8b;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keyboard {
  font-size: min(6vw, 24px);
  margin: 1em;
}

.words-outer-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.letter.blank,
.keyboard .letter {
  background: #9e9e9e;
}

.keyboard .letter {
  width: 1.25em;
  border-radius: 5px;
}

.keyboard .letter.action {
  width: 2em;
}

.letter.used {
  background: #4caf50;
}

.display-word {
  display: flex;
}

.letter.first {
  background: #4caf50;
}

div.index {
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 2em;
  width: 2em;
  justify-content: center;
}

.keyboard-wrapper {
  display: flex;
  justify-content: center;
}

.words-wrapper {
  display: flex;
  justify-content: center;
  font-size: 30px;
}

div.header {
  background: #333333;
  color: white;
  display: flex;
  text-align: center;
  padding: 20px;
}

div.title {
  flex-grow: 1;
  font-size: 1.6em;
  font-weight: bold;
}

div.header svg {
  font-size: 1.6em;
}

div.menu-control,
div.help,
div.scores {
  width: 30px;
}

div.help svg,
div.scores svg {
  cursor: pointer;
}

div.help {
  margin-right: 10px;
}

div#end-screen div.stats {
  display: flex;
}

div#end-screen div.stats div.stat {
  width: 50px;
  margin: 0 10px;
}

div#end-screen div.stats div.stat div.number {
  font-size: 30px;
  font-weight: bold;
}

div#end-screen div.stats div.stat div.name {
  font-size: 10px;
  font-weight: lighter;
}

div.distro-bar {
  display: flex;
  width: 100%;
  text-align: left;
  font-weight: 400;
  margin-bottom: 5px;
}

div.distro-bar div.number {
  width: 20px;
}

div.distro-bar div.bar-wrapper {
  flex-grow: 1;
}

div.distro-bar div.bar {
  background: #9e9e9e;
  text-align: right;
  color: white;
  padding: 3px 5px;
  box-sizing: border-box;
}

div.distro-bar div.bar.current {
  background: #4caf50;
}

.next-share {
  display: flex;
  text-align: center;
}

.next-share div {
  flex-grow: 1;
}

.next-share div.next div.countdown {
  font-size: 30px;
}

.next-share div.share button {
  background: #4caf50;
  color: white;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
